import * as yup from 'yup';

import { USA } from '../constants/countries';
import {
  RECEIVER_ADDRESS1,
  RECEIVER_ADDRESS2,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  RECEIVER_COUNTRY,
  RECEIVER_EMAIL,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  RECEIVER_STATE,
  RECEIVER_ZIP,
} from '../constants/shipping';
import { CUSTOMIZABLE_ITEM_TEXT_MAX_LENGTH } from '../constants/ui';
import { ICustomizableViewItem } from '../types/inventory';

const EMAIL_VALIDATION_REGEX = /^[\w._%+\-]+@[\w.\-]+\.[A-Za-z]{2,4}$/gi;

// phone field full validation for possible use in future
// const phoneTest: TestOptions = {
//   name: 'is-phone-number',
//   message: () => `Phone must be a valid phone number.`,
//   test: (value: unknown) => typeof value === 'string' && isValidPhoneNumber(value),
// };

export const physicalFormValidation = yup.object().shape({
  [RECEIVER_FIRST_NAME]: yup.string().required('First name is required').trim(),
  [RECEIVER_LAST_NAME]: yup.string().required('Last name is required').trim(),
  [RECEIVER_ADDRESS1]: yup.string().required('Address 1 is required').trim(),
  [RECEIVER_ADDRESS2]: yup.string().trim(),
  [RECEIVER_CITY]: yup.string().required('City is required').trim(),
  [RECEIVER_COMPANY_NAME]: yup.string().trim(),
  [RECEIVER_COUNTRY]: yup.string().required('Country is required').trim(),
  [RECEIVER_EMAIL]: yup
    .string()
    .matches(EMAIL_VALIDATION_REGEX, 'Invalid email format')
    .required('Email is required')
    .trim(),
  [RECEIVER_PHONE]: yup.string().required('Phone is required').trim(),
  [RECEIVER_STATE]: yup
    .string()
    .trim()
    .when(RECEIVER_COUNTRY, {
      is: USA.two_digit_code,
      then: (schema: yup.StringSchema) => schema.required('State is required'),
      otherwise: (schema: yup.StringSchema) => schema.notRequired(),
    }),
  [RECEIVER_ZIP]: yup.string().required('ZIP Code is required').trim(),
});

export const digitalFormValidation = yup.object().shape({
  [RECEIVER_FIRST_NAME]: yup.string().required('First name is required').trim(),
  [RECEIVER_LAST_NAME]: yup.string().required('Last name is required').trim(),
  [RECEIVER_EMAIL]: yup
    .string()
    .matches(EMAIL_VALIDATION_REGEX, 'Invalid email format')
    .required('Email is required')
    .trim(),
  [RECEIVER_PHONE]: yup.string().required('Phone is required').trim(),
});

export const customizedTextsValidation = (items: (ICustomizableViewItem | null)[] | null) => {
  return yup.object().shape(
    items?.length
      ? items.reduce((acc, item) => {
          const { internalId, item_customization_required } = item || {};
          if (internalId) {
            const commonSchema = yup
              .string()
              .max(CUSTOMIZABLE_ITEM_TEXT_MAX_LENGTH, ({ max }) => `Maximum length is ${max} characters`)
              .trim();
            return {
              ...acc,
              [internalId]: item_customization_required
                ? commonSchema.required('This field is required')
                : commonSchema.notRequired(),
            };
          }
          return { ...acc };
        }, {} as { [k: string]: yup.StringSchema })
      : {},
  );
};

export const isPhoneNumber = (inputValue: string) => {
  return /[1-9]\d{1,14}$/.test(inputValue);
};

export const isValidEmail = (email: string) => new RegExp(EMAIL_VALIDATION_REGEX).test(email);
